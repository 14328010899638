@import 'src/design-system/color-palette/color-palette.scss';

.wrapper {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 600px) {
    font-size: 22px;
  }

  .titleWrapper {
    width: 100%;
    padding: 0 24px;
    margin-top: 64px;
    box-sizing: border-box;
    .title {
      display: block;
      margin-bottom: 8px;
    }

    .content {
      color: $base_gray6;
    }
  }

  .submitBtn {
    margin: 0 auto;
    margin-bottom: 16px;
    width: calc(100% - 32px);
    height: 56px;
    padding: 16px;
    font-weight: bold;
  }
}
