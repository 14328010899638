.showInView {
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  transform: translateY(20px);
  opacity: 0;

  &.visible {
    transform: translateY(0);
    opacity: 1;
  }
}
