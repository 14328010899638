@import '../../color-palette/color-palette.scss';

.box {
  border-width: 1px;
  border-style: solid;
  transition: 0.2s all;
  &.blue {
    background: $brandcolor_8;
    border-color: $brandcolor_primary;
  }
  &.red {
    background: $warning_8;
    border-color: $warning_primary;
  }
  &.yellow {
    background: $caution_primary;
    border-color: $caution_8;
  }
  &.gray {
    background: $base_gray2;
    border-color: $base_gray3;
  }
  &.white {
    background: $base_white;
    border-color: $base_gray3;
  }
}
