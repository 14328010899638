@import '../../styles/variables.scss';

.preInspectedCarProgressDescriptionForDealerPage {
  font-family: 'Spoqa Han Sans', 'SDGothicNeo1', 'Malgun Gothic', Sans-Serif, serif;
  max-width: 560px;
  margin: 0 auto;
  .container {
    font-size: 14px;
    padding: 0px 20px;
    .hr1 {
      margin: 0;
      border-top: 1px solid $navy-4;
      fill: none;
      border-left: none;
    }
  }
  .hr2 {
    width: 100%;
    height: 8px;
    background-color: $navy-2;
  }
}
