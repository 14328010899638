@import '../../../design-system/color-palette/color-palette.scss';

.container {
  padding: 16px;
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .content {
    font-size: 14px;
    line-height: 24px;
    word-break: break-all;
  }
}
.critical {
  .header {
    .icon {
      fill: $critical_dark;
    }
    .title {
      color: $critical_dark;
    }
  }
  background: $critical_8;
  border: 1px solid $critical_dark;
}
.warning {
  .header {
    .icon {
      fill: $warning_primary;
    }
    .title {
      color: $warning_primary;
    }
  }
  background: $warning_8;
  border: 1px solid $warning_primary;
}
.error {
  padding: 0;
  .header {
    .icon {
      fill: $warning_primary;
    }
    .title {
      color: $warning_primary;
    }
  }
}
