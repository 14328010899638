.container {
  font-family: 'Spoqa Han Sans', 'SDGothicNeo1', 'Malgun Gothic', Sans-Serif, serif;
  max-width: 600px;
  margin: 0 auto;
  font-size: 14px;
  padding: 1.714em 0;
  padding-bottom: 3.428em;
  @media (min-width: 600px) {
    font-size: 16px;
  }

  .paragraph {
    color: #272e40;
    font-size: 1em;
    line-height: 1.714em;
    .content {
      margin-top: 10px;
    }

    .link {
      text-decoration: none;
      color: #396eff;
      cursor: pointer;
    }
  }

  .header {
    margin-bottom: 1.714em;
    padding: 0 1.714em;
    h1 {
      display: inline-block;
      color: #272e40;
      font-size: 1.714em;
      line-height: 36px;
      font-weight: bold;
      margin-bottom: 1em;
    }
  }

  .teslaOptionListWrapper {
    margin: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(237, 237, 237, 0.24);
    padding: 30px;

    .teslaOptionList {
      width: 210px;
    }
  }

  .body {
    padding: 0 1.714em;
    .subTitle {
      font-size: 1.143em;
      font-weight: bold;
      line-height: 28px;
    }
    .list {
      margin-top: 10px;
      .indent {
        padding-left: 17px;
      }
      .listItem {
        display: flex;
        .number {
          margin-right: 4px;
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
}
