@import '../../../styles/variables.scss';

.noItemWrapper {
  text-align: center;
  padding: 16px 0;
  .noItemImage {
    width: 120px;
    height: 80px;
    margin-bottom: 8px;
  }
  .noItemText {
    color: $navy-10;
  }
}
