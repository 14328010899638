.process {
  position: relative;

  padding-bottom: 16px;
  padding-left: 12px;

  .image {
    width: 100%;

    margin-top: 20px;

    transform: translateY(40px);
    opacity: 0;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

    transition-delay: 0.1s;
  }

  .dummy {
    visibility: hidden;
  }

  .bigDot {
    position: absolute;
    top: 10px;
    left: 3px;

    width: 5px;
    height: 5px;

    border-radius: 50%;

    background-color: #396eff;
  }

  &:not(:last-child) {
    .dotted {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &:last-child {
    .dotted {
      display: none;
    }
  }
}
