@import "../../../../styles/colors.scss";

.titleWrapper {
  display: flex;
  align-items: center;
}

.step {
  font-family: "Spoqa Han Sans", "SDGothicNeo1", "sans-serif";

  display: flex;
  align-items: center;
  justify-content: center;

  background: $brand_primary;
  color: $base_white;

  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 8px;

  font-size: 10px;
  line-height: 16px;

  font-weight: bold;
}
