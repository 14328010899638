@import '../../../styles/variables.scss';

.bodySectionWrapper {
  .singleSection {
    margin-top: 40px;
    margin-bottom: 40px;
    .sectionTitle {
      font-size: 18px;
    }
    .sectionImage {
      margin-top: 16px;
      width: 100%;
    }
    .sectionContentText {
      margin-top: 16px;
      .yellow {
        font-weight: normal;
        color: $macaroni_and_cheese;
      }
    }
  }
}
