@import '../../styles/variables.scss';
@import '../../design-system/color-palette/color-palette.scss';

.wrapper {
  margin: 0 24px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .starContainer {
    margin: 0 auto;
  }

  .star {
    margin: 6px;
  }

  .textareaWrapper {
    width: 100%;
    height: 120px;
    margin: 16px 0 0;
    display: block;

    .textarea {
      width: 100%;
      height: 100% !important;
    }
  }
}
