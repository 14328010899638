@import 'src/design-system/color-palette/color-palette';

.level_0 {
  background-color: $base_white;
}

.level_2 {
  background-color: $base_white;
  box-shadow: 0 2px 4px $base_8;
}

.level_8 {
  background-color: $base_white;
  box-shadow: 0 8px 16px $base_8;
}
