.wrapper {
  padding-top: 24px;
  .inner {
    padding: 40px 32px;
    .emoji {
      height: 80px;
      width: 80px;
      margin-bottom: 16px;
    }

    .bigText {
      margin-bottom: 16px;
    }
  }
}