$blue-dark  : #3362e4;
$blue       : #396eff;
$blue-light : #608bff;

$navy       : #272e40;
$navy-14    : #10131a;
$navy-12    : #414d6b;
$navy-10    : #869ab7;
$navy-8     : #afc2db;
$navy-6     : #ccd8e8;
$navy-4     : #e9edf4;
$navy-2     : #f7f8fb;
$navy-0     : #fbfcff;

$white      : #ffffff;

$green-light: #71f3c4;
$green      : #32e5b1;
$green-dark : #22c886;

$red        : #ff5d5d;

$yellow     : #f5b923;
$macaroni_and_cheese  : #e5bd48;