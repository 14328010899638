.process {
  padding-left: 19px;
  position: relative;
  padding-bottom: 60px;
  min-height: 250px;
  &:first-child {
    padding-bottom: 80px;
  }
  h2 {
    font-size: 1.143em;
    line-height: 1.429em;
    color: #396eff;
  }
  .desc {
    margin-top: 4px;
    font-size: 1em;
    line-height: 1.714em;
    color: #272e40;
  }
  .info {
    margin-top: 4px;
    font-size: 0.857em;
    line-height: 1.429em;
    color: #869ab7;
  }
  .image {
    margin-top: 20px;
    width: 100%;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: translateY(40px);
    opacity: 0;
    transition-delay: 0.1s;
  }
  .dummy {
    visibility: hidden;
  }
  .view {
    transform: translateY(0);
    opacity: 1;
  }
  .bigDot {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #396eff;
    top: 6px;
    left: 1.2px;
    border-radius: 50%;
  }
  &:not(:last-child) {
    .dotted {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &:last-child {
    .dotted {
      display: none;
    }
    padding-bottom: 40px;
  }
}
