@import "../../styles/mixin";

.faq {
  transform: translate3d(0, 0, 0);
  transition: height 0.3s ease-in-out;

  overflow: hidden;
  will-change: height;

  .title {
    @include scale(font-size, 14px);
    @include scale(line-height, 16px);
    @include scale(padding, 16px 28px);
    position: relative;

    width: 100%;

    border: none;

    background: none;
    color: #272e40;

    text-align: left;
    will-change: height;

    &::after {
      @include scale(width, 16px);
      @include scale(height, 16px);
      @include scale(right, 28px);
      position: absolute;
      top: 0;
      bottom: 0;

      margin: auto;

      background: url(../../images/ic_arrow_down_gray.svg) no-repeat center;

      transition: transform 0.15s ease-in-out;

      content: "";
      background-size: 100%;
      pointer-events: none;
    }
  }

  .content {
    display: flex;

    position: relative;

    transform: translate(0, 0);

    .inner {
      @include scale(padding, 4px 28px 28px 28px);
      display: flex;
    }

    .icon {
      @include scale(width, 16px);
      @include scale(height, 16px);
      @include scale(min-width, 16px);
      @include scale(min-height, 16px);
      @include scale(margin-top, 4px);
      @include scale(margin-right, 4px);
      background: url(../../images/ic_low_gray.svg) no-repeat center;

      background-size: 100%;
    }

    .html {
      @include scale(font-size, 13px);
      @include scale(line-height, 24px);

      a {
        color: #396eff;
      }
    }
  }

  &.isVisible {
    .title {
      font-weight: bold;

      &::after {
        transform: scaleY(-1);
      }
    }
  }
}

.questionPage {
  margin: 0 auto;

  max-width: 720px;

  .appBar {
    .leftPadding {
      padding-left: 20px;
    }
    @include scale(height, 56px);
    @include scale(margin-bottom, 16px);
    display: flex;
    align-items: center;

    .backButton {
      @include scale(width, 48px);
      @include scale(height, 48px);
      @include scale(margin, 4px);
      @include scale(background-size, 24px 24px);
      display: flex;

      position: relative;

      padding: 0;

      border: none;

      background: url(../../images/ic_mainbar_back.svg) center no-repeat;
    }

    .title {
      word-break: keep-all;
      @include scale(font-size, 16px);
      @include scale(line-height, 20px);
    }
  }

  .additional {
    @include scale(padding, 32px 24px);
    @include scale(border-top, solid #f7f8fb 16px);
    display: flex;
    flex-direction: column;

    transform: translate(0, 0);

    will-change: top, left, height;

    .title {
      @include scale(font-size, 16px);
      @include scale(line-height, 20px);
      @include scale(margin-bottom, 8px);
    }

    .description {
      @include scale(font-size, 14px);
      @include scale(line-height, 24px);
      @include scale(margin-bottom, 8px);
      font-weight: normal;
    }

    .time {
      @include scale(font-size, 12px);
      @include scale(line-height, 16px);
      @include scale(margin-bottom, 20px);
      color: #869ab7;

      font-weight: normal;
    }

    .chatButton {
      @include scale(font-size, 16px);
      @include scale(width, 127px);
      @include scale(text-indent, 26px);
      @include scale(height, 56px);
      @include scale(border-radius, 4px);
      @include scale(box-shadow, 0 2px 4px 0 rgba(57, 110, 255, 0.16));
      display: flex;
      justify-content: center;
      align-items: center;

      position: relative;

      border: none;

      color: #fff;

      background-color: #396eff;
      background-image: url(../../images/ic_chat_white.svg);
      background-repeat: no-repeat;
      text-align: center;
      text-decoration: none;
      @include scale(background-size, 16px);
      @include scale(background-position, left 22px center);
    }
  }
}
