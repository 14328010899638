@import 'src/design-system/color-palette/color-palette';
.content {
  padding: 20px 24px;
  .title {
    margin-bottom: 20px;
  }
  .subTitle {
    margin-bottom: 8px;
    color: $brandcolor_primary;
  }
  .listItem {
    display: flex;
    align-items: top;
    word-break: keep-all;
    .confirmPass {
      margin-top: 6px;
      margin-right: 8px;
      min-width: 16px;
    }
  }
  .table {
    margin-top: 24px;
    width: 100%;
    th {
      background-color: $brandcolor_primary;
      padding: 14px 0;
      &:not(:first-child) {
        border-left: 1px solid $base_white;
      }
      .thText {
        color: $base_white;
      }
    }
    td {
      border-left: 1px solid $base_white;
      background-color: $base_gray2;
      padding: 14px 0;
      text-align: center;
      &:not(:first-child) {
        border-left: 1px solid rgba(39, 46, 64, 0.08);
      }
    }
    .tbodyTr {
      &:not(:first-child) {
        border-top: 1px solid rgba(39, 46, 64, 0.08);
      }
    }
  }
}
