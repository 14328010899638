@import '../../../color-palette/color-palette.scss';


.wrapper {
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: $base_white;
  border-color: $base_gray3;
  cursor: pointer;
  transition: 0.2s all;
  -webkit-tap-highlight-color: transparent;

  &.big {
    padding: 14px 16px;
  }
  &.small {
    padding: 12px 16px;
  }
  &.list {
    background-color: transparent !important;
    border-width: 0 !important;
  }
  &.box {
    border-width: 1px;
    border-style: solid;
  }

  .radio {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    display: block;
    border-color: $base_gray4;
    background-color: $base_white;
    transition: 0.1s all;
    margin-right: 12px;

    .checkIcon {
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      box-sizing: border-box;
      top: 3px;
      left: 3px;
    }
  }

  @media (hover: hover) {
    &:hover {
      border-color: $base_gray6;
      background-color: $base_gray1;
      .radio {
        border-color: $base_gray6;
        background-color: $base_gray1;
      }
    }
  }
  &:focus-visible,
  &:focus {
    border-color: $brandcolor_primary;
    background-color: $base_white;
    .radio {
      border-color: $brandcolor_primary;
      background-color: $base_white;
    }
  }
  &.checked {
    border-color: $brandcolor_primary;
    background-color: $brandcolor_8;
    .radio {
      border-color: $brandcolor_primary;
      .checkIcon {
        background-color: $brandcolor_primary;
      }
    }
  }
  &.warning {
    border-color: $warning_primary;
    background-color: $base_white;
    &.checked {
      background-color: $warning_8;
      .radio {
        border-color: $warning_primary;
        .checkIcon {
          background-color: $warning_primary;
        }
      }
    }
    .radio {
      border-color: $warning_primary;
      background-color: $base_white;
    }
  }
  &.disabled {
    border-color: $base_gray3;
    background-color: $base_gray1;
    .radio {
      border-color: $base_gray3;
      background-color: $base_gray2;
    }
  }
}

.disabledTypo {
  color: $base_gray4;
}
