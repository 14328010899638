@import "../../design-system/color-palette/color-palette.scss";

.container {
  padding: 2rem 1.5rem;
  .header {
    display: grid;
    gap: 1rem;
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .time {
        color: $base_gray6;
      }
      .reConfirmIcon {
        fill: $brandcolor_primary;
        width: 0.875rem;
        height: 1rem;
      }
    }
  }
  .infoSection {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .border {
    margin: 1.5rem 0;
  }
  .subTitle {
    margin-bottom: 0.5rem;
  }
  .paragraph {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    .subTitle2 {
      color: $base_gray6;
    }
    .emoji {
      width: 1rem;
      height: 1rem;
    }
  }
}

.warningText {
  color: $warning_primary;
}
.criticalText {
  color: $critical_dark;
}
