@import 'src/design-system/color-palette/color-palette.scss';

.wrapper {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  @media (min-width: 600px) {
    font-size: 22px;
  }

  .titleWrapper {
    width: 100%;
    padding: 20px 24px 24px 24px;
    box-sizing: border-box;

    .title {
      display: block;
      margin-bottom: 20px;
    }
  }

  .radioWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 24px 32px;
    margin-top: 16px;

    .selectedBox {
      border-radius: 4px;
      .notSellDescription {
        padding: 16px;
        .notSellDescriptionCaption {
          color: $brandcolor_primary;
        }
        .notSellDescriptionInput {
          margin-top: 8px;
          position: relative;

          &::after {
            content: '만원';
            position: absolute;
            right: 16px;
            top: 12px;
            font-size: 16px;
            line-height: 28px;
            color: $base_gray4;
          }

          &.hasValue::after {
            color: $base_dark;
          }
        }
      }
      .subTitle {
        margin-bottom: 8px;
      }
    }
  }

  // Provide not-fixed div as parent of fixed button
  // so that it can have fixed position and also have enough padding below the contents
  .buttonArea {
    box-sizing: border-box;
    width: 100%;
    height: calc(88px + constant(safe-area-inset-bottom));
    height: calc(88px + env(safe-area-inset-bottom));
    padding: 16px;

    .submitBtn {
      position: fixed;
      bottom: calc(16px + constant(safe-area-inset-bottom));
      bottom: calc(16px + env(safe-area-inset-bottom));

      margin: 0 auto;
      width: calc(100% - 32px);
      max-width: calc(600px - 32px);
      height: 56px;
      padding: 16px;
      font-weight: bold;

      z-index: 1;
    }
  }
}

.layer {
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
}

.reasonWrapper {
  margin-top: 80px;
  padding-bottom: 56px;
}
