.imageWrapper {
  position: relative;
  width: 240px;
  height: 140px;
  margin-bottom: 16px;
  border-radius: 4px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.delete {
  cursor: pointer;
  width: 28px;
  height: 28px;
  background: url(../../../../images/customerIdentification/btn-delete.svg) no-repeat center;
  background-size: 100%;
  position: absolute;
  right: -8px;
  top: -8px;
  border: none;
}
