$brand_dark: rgba(51, 98, 228, 1);
$brand_light: rgba(96, 139, 255, 1);
$brand_primary: rgba(57, 110, 255, 1);
$brand_32: rgba(57, 110, 255, 0.32);
$brand_16: rgba(57, 110, 255, 0.16);
$brand_8: rgba(57, 110, 255, 0.08);
$base_dark: rgba(16, 19, 26, 1);
$base_primary: rgba(39, 46, 64, 1);
$base_light: rgba(65, 77, 107, 1);
$base_32: rgba(39, 46, 64, 0.32);
$base_16: rgba(39, 46, 64, 0.16);
$base_8: rgba(39, 46, 64, 0.08);
$base_gray6: rgba(134, 154, 183, 1);
$base_gray5: rgba(175, 194, 219, 1);
$base_gray4: rgba(204, 216, 232, 1);
$base_gray3: rgba(233, 237, 244, 1);
$base_gray2: rgba(247, 248, 251, 1);
$base_gray1: rgba(251, 252, 255, 1);
$base_white: rgba(255, 255, 255, 1);
$success_dark: rgba(26, 197, 149, 1);
$success_primary: rgba(34, 226, 172, 1);
$success_light: rgba(97, 234, 196, 1);
$success_32: rgba(34, 226, 172, 0.32);
$success_16: rgba(34, 226, 172, 0.16);
$success_8: rgba(34, 226, 172, 0.08);
$warning_dark: rgba(255, 67, 67, 1);
$warning_primary: rgba(255, 88, 93, 1);
$warning_light: rgba(255, 127, 127, 1);
$warning_32: rgba(255, 88, 93, 0.32);
$warning_16: rgba(255, 88, 93, 0.16);
$warning_8: rgba(255, 88, 93, 0.08);
$caution_dark: rgba(245, 159, 0, 1);
$caution_primary: rgba(255, 176, 32, 1);
$caution_light: rgba(255, 193, 79, 1);
$caution_32: rgba(245, 185, 35, 0.32);
$caution_16: rgba(245, 185, 35, 0.16);
$caution_8: rgba(245, 185, 35, 0.08);
$critical_dark: rgba(255, 124, 29, 1);
$critical_8: rgba(255, 124, 29, 0.08);
