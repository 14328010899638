.faqCollapse {
  cursor: pointer;
  align-items: center;
  color: #272e40;
  .titleWrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1.429em 1.714em;
    transition: font-weight 0.15s ease-in-out;
    justify-content: space-between;
    height: 3.429em;
    box-sizing: border-box;
    .title {
      font-size: 1em;
      display: flex;
      align-items: center;
      .icon {
        margin-right: 10px;
      }
    }
    .arrowIcon {
      transition: transform 0.15s ease-in-out;
    }
  }
  .contentWrapper {
    transition: height 0.3s ease-out, opacity 0.3s ease-out;
    overflow: hidden;
    padding: 8px 24px 0 24px;
    font-size: 1.143em;
    line-height: 1.71;
    color: #272e40;
    .content {
      padding-left: 26px;
      font-size: 14px;
      white-space: pre-line;
    }
  }
}

.isOpen {
  .title {
    font-weight: bold;
  }
  .contentWrapper {
    padding-bottom: 16px;
  }
  .arrowIcon {
    transform: rotate(180deg);
  }
}
