@import "../../styles/colors.scss";

.container {
  margin: 0 auto;

  max-width: 600px;
}

.progress {
  box-sizing: border-box;
  width: 100%;

  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;

  &:first-of-type {
    padding-top: 1.5rem;
  }

  &:last-of-type {
    padding-bottom: 5rem;
  }

  .progressTextArea {
    display: grid;
    gap: 0.5rem;
  }

  .progressImageArea {
    display: grid;
    justify-content: center;
    align-items: flex-start;

    box-sizing: border-box;
    width: 100%;

    margin-top: 1.5rem;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.판매서류비디오 {
  width: 150%;

  margin-top: 0.5rem;
}

.탁송기사도착비디오 {
  width: auto;
  height: 14rem;

  max-width: 100%;
}
