@import '../../../styles/variables.scss';

.headSectionWrapper {
  width: 100%;
  .headTitle {
    height: 80px;
    line-height: 80px;
    color: $navy-12;
    margin-bottom: 8px
  }
  .blueSectionWrapper {
    padding: 8px 12px;
    border: 1px solid $blue;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba($blue, 0.15);
    border-radius: 4px;
    background-color: rgba(103, 157, 255, 0.04);
    margin-top: 16px;
    margin-bottom: 27.7px;
    p {
      line-height: 20px;
    }
  }
}
