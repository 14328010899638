@import '../../../../styles/variables.scss';

.FAQHeader {
  position: relative;
  height: 56px;
  margin-left: -20px;
  margin-right: -20px;
  cursor: pointer;
  .q {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 20px;
    left: 20px;
  }
  .FAQTitle {
    position: absolute;
    left: 44px;
    font-size: 14px;
    line-height: 24px;
    color: $navy-12;
    top: 16px;
    font-weight: bold;
  }
  .vWrapper {
    width: 56px;
    height: 56px;
    position: absolute;
    top: 0;
    right: 0;
    .v {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 16px;
      height: 16px;
      transition: all 0.2s;
      &.opened {
        transform: rotate(180deg);
      }
    }
  }
}

.FAQContent {
  padding-left: 24px;
  overflow: hidden;
  transition: height;
  .content {
    white-space: pre-line;
  }
}
