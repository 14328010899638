@import 'src/design-system/color-palette/color-palette';

.createLabel {
  width: 240px;
  height: 140px;
  border: 1px dashed $brandcolor_primary;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(57, 110, 255, 0.16);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .icon {
    margin-right: 8px;
  }
  .labelWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .label {
      display: flex;
      align-items: center;
      color: $brandcolor_primary;
    }
    .info {
      text-align: center;
      margin-top: 4px;
      color: $base_gray6;
    }
  }
}

.fileInput {
  display: none;
}
