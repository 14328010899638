@import '../../design-system/color-palette/color-palette';

.customerIdentification {
  @media (min-width: 600px) {
    border: solid 1px rgba(233, 237, 244, 1);
    box-shadow: 0 2px 4px rgba(39, 46, 64, 0.08);
  }
  padding-bottom: 16px;
  .section1 {
    padding: 24px 24px 40px 24px;
    border-bottom: 1px solid $base_gray3;
    .description {
      margin-top: 8px;
    }
    .info {
      margin-top: 8px;
      color: $base_gray6;
    }
    .notice {
      margin-top: 16px;
      color: $brandcolor_primary;
      text-align: center;
    }
    .imageInputWrapper {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }
  }
  .section2 {
    padding: 40px 24px 40px 24px;
    .subTitle {
      color: $base_gray6;
    }
    .privacyPolicy {
      margin-top: 16px;
      padding: 16px;
      color: $base_gray6;
      background: $base_gray2;
      border: 1px solid $base_gray3;
    }
    .privacyPolicyCheck {
      margin-top: 16px;
      box-sizing: border-box;
      width: 100%;
    }
  }
  .submitButton {
    width: calc(100% - 32px);
    margin: 0 auto;
    box-sizing: border-box;
    &:disabled {
      border: 1px solid $base_gray3;
    }
  }


  .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }

}
