.indexUpEmojiWrapper {
  position: relative;
  line-height: 20px;
  height: 20px;
  margin-right: 4px;
  .indexUpEmoji {
    width: 18px;
    position: relative;
    top: 2px;
  }
}
