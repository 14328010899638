.container {
  font-family: 'Spoqa Han Sans', 'SDGothicNeo1', 'Malgun Gothic', Sans-Serif, serif;
  max-width: 600px;
  margin: 0 auto;
  font-size: 14px;
  padding-top: 52px;
  @media (min-width: 600px) {
    font-size: 16px;
  }
  .body {
    padding: 0 1.714em;
    .header {
      padding: 1.714em 0;
      h1 {
        display: inline-block;
        color: #272e40;
        font-size: 1.714em;
        line-height: 36px;
        font-weight: bold;
      }
    }
    .paragraph {
      color: #272e40;
      font-size: 1em;
      line-height: 1.714em;
      .content {
        padding-left: 12px;
      }
      .subTitle {
        font-size: 1.143em;
        font-weight: bold;
        line-height: 28px;
      }
      &:first-child {
        margin-bottom: 1.714em;
      }
      .list {
        padding-left: 11px;
        margin-top: 8px;
      }
    }
    .newTag {
      margin-left: 0.571em;
    }

    .section1 {
      border-bottom: 1px solid #f7f8fb;
      padding-bottom: 2.5em;
      .description {
        line-height: 16px;
        font-size: 0.857em;
        position: relative;
        margin-top: 4px;
        color: #869ab7;
        padding-left: 23px;
      }
    }
  }
}
