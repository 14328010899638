@import "../../../../styles/colors.scss";
@import "src/styles/variables";

.icon {
  fill: $brand_primary;
  transition: transform 0.15s ease-in-out;
  &.open {
    transform: rotate(-180deg);
  }
}

.body {
  section {
    margin-bottom: 24px;
    .box {
      word-break: keep-all;
      padding: 12px;
      background-color: $navy-0;
      border: 1px solid $navy-4;
      border-radius: 4px;
      li {
        margin-left: 8px;
        text-indent: -8px;
      }
    }

    table {
      background-color: $navy-0;
      border-collapse: collapse;
      border-radius: 4px;
      width: 100%;
      td {
        border: 1px solid $navy-4;
        padding: 8px 12px;
      }
    }
  }
}
