@import "../../styles/colors.scss";

.appBar {
  display: flex;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  padding: 4px;
  padding-top: calc(env(safe-area-inset-top) + 4px);
  background-color: $base_white;
}

.isFixed {
  position: fixed;
  top: 0;
  z-index: 1000;
}

.isNotTop {
  opacity: 0.9;
}
