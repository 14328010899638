@import '../../styles/variables.scss';
@import '../../styles/mixin';

.typography {
  font-family: 'Spoqa Han Sans', 'SDGothicNeo1', 'Malgun Gothic', Sans-Serif, serif;
  font-stretch: normal;
  letter-spacing: normal;
  color: $navy;
}

.headline_xl_m {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
}
.headline_l_m {
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
}
.headline_m {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}
.body_l_m {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
.body_m {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.caption_m {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
.small_text_m {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}
.subtitle_l_m {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}
.subtitle_m {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
}
.subtitle_s_m {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}
.headline_lt_m {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
}
.headline_xl {
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
}
.headline_l {
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
}
.headline {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}
.headline_lt {
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
}
.subtitle_l {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.subtitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}
.subtitle_s {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}
.caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.small_text {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}
.body_l {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.body {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.material_light_headline {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.87);
}
.subtitle_xs {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}
.bodyMulti {
  font-size: 14px;
  line-height: 24px;
  color: $navy-12;
}
.Headline_40 {
  font-weight: bold;
  font-size: 32px;
  line-height: 52px;
}

.Headline_32 {
  font-weight: bold;
  font-size: 32px;
  line-height: 52px;
}

.Headline_24 {
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
}

.Headline_20 {
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
}

.Headline_24_L {
  font-size: 24px;
  line-height: 40px;
}

.Subtitle_20 {
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
}

.Subtitle_16 {
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
}

.Subtitle_14 {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

.Subtitle_12 {
  font-size: 12px;
  line-height: 20px;
}

.Body_16 {
  font-size: 16px;
  line-height: 28px;
}

.Body_14 {
  font-size: 14px;
  line-height: 24px;
}

.Caption {
  font-size: 12px;
  line-height: 20px;
}
