@import "../../../../styles/colors.scss";

.safeTradeSection {
  background-color: $base_gray2;

  .commentImage {
    display: block;

    height: 14.375rem;

    margin: 2rem auto 1rem;
  }

  .content {
    padding: 0 2rem 2rem;

    .container {
      height: 56px;

      margin-top: 12px;

      border: 1px solid #e9edf4;
      border-radius: 4px;

      box-shadow: 0 2px 4px rgba(39, 46, 64, 0.08);

      transition: height 0.3s ease-in-out, background-color 0.15s ease-in-out;

      overflow: hidden;

      .safeTradeButton {
        display: flex;
        justify-content: space-between;
        align-items: center;

        position: relative;

        width: 100%;
        height: 56px;

        margin: 0;

        padding: 14px 18px;

        border: none;
        border-radius: 4px;

        background-color: $base_white;
        cursor: pointer;
        font-size: 16px;
        line-height: 28px;

        .titleWrapper {
          display: flex;
          align-items: center;
        }

        .safeIcon {
          margin-right: 10px;
        }

        .arrowDown {
          transition: fill 0.15s ease-in-out, transform 0.15s ease-in-out;

          fill: #afc2db;
        }

        .openArrow {
          transform: rotate(180deg);

          fill: #396eff;
        }
      }

      .openSafeTradeButton {
        background-color: transparent;
      }

      .content {
        box-sizing: border-box;

        padding: 0 16px 16px;
      }

      .info {
        color: #414d6b;

        line-height: 20px;
      }
    }

    .openContainer {
      border: 1px solid #396eff;

      background: #eff3ff;
    }

    .tradeProcess {
      position: relative;

      .line {
        position: absolute;
        top: 10px;
        left: 5px;

        width: 1px;
        height: 75%;

        background-image: url("../../assets/img/line.svg");
      }
    }
  }
}
